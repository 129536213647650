
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import SearchTable from '@/components/table/SearchTable.vue';
import { useGetters } from '@/store/mixin';

export default defineComponent({
  name: 'AllocFlow',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'no',
              label: '划拨单号'
            },
            {
              field: 'sn',
              label: '机具'
            },
            {
              field: 'operatorId',
              label: '划拨人',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'agentId',
              label: '接收人',
              type: 'agent'
            },
          ]
        },
        remote: {
          action: https?.device.pageAllocFlow
        }
      },
      cols: [
        {
          field: 'sn',
          label: '机具号'
        },
        {
          field: 'no',
          label: '划拨单号'
        },
        {
          field: 'backerNo',
          label: '品牌号'
        },
        {
          field: 'modelIdTag',
          label: '二级分类'
        },
        {
          field: 'freezeFlag',
          label: '冻结类型'
        },
        {
          field: 'activateDesc',
          label: '激活政策'
        },
        {
          field: 'operator',
          label: '划拨人'
        },
        {
          field: 'operatorAwardPolicyTag',
          label: '划拨人返现政策'
        },
        {
          field: 'agentAlias',
          label: '接收人'
        },
        {
          field: 'agentAwardPolicyTag',
          label: '接收返现政策'
        },
        {
          field: 'createTime',
          label: '创建时间'
        },
        {
          field: 'updateTime',
          label: '更新时间'
        }
      ]
    };

    return { tableOpts };
  }
});
